@tailwind base;
@tailwind components;
@tailwind utilities;

.form-radio {
  @apply shadow-inner appearance-none inline-block align-middle select-none flex-shrink-0 rounded-full h-5 w-5 text-primary bg-white border border-gray-300 transition-colors;
  background-origin: border-box;
}

.form-radio:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='4'/%3E%3C/svg%3E");
  background-size: 100% auto;
  @apply bg-current bg-center bg-no-repeat border-transparent h-5 w-5;
}

.form-radio:hover:not(:checked) {
  @apply border-gray-400;
}

.form-radio:focus {
  @apply ring ring-primary ring-opacity-20 outline-none;
}

a {
  @apply underline text-primary;
}

.btn {
  @apply text-base rounded-full inline-flex items-center leading-tight font-medium transition-colors;
}

.btn:not([class*='px-']) {
  @apply px-6;
}

.btn:not([class*='py-']) {
  @apply py-4;
}

.btn.font-bold {
  font-weight: 700;
}

.btn--empty {
  @apply bg-white border hover:bg-gray-100 focus:border-gray-500;
}

.btn--default {
  @apply bg-primary-background-dark border hover:bg-primary-background focus:border-gray-500;
}

.btn--primary {
  @apply bg-primary border border-primary text-white hover:bg-primary-light focus:bg-primary-light;
}

.btn--primary[disabled] {
  @apply bg-gray-200 text-gray-800 border-gray-200 cursor-not-allowed;
}

.btn--positive {
  @apply bg-green-500 border border-green-500 text-white hover:bg-green-400 focus:bg-green-400;
}

.btn--negative {
  @apply bg-red-500 border border-red-500 text-white hover:bg-red-400 focus:bg-red-400;
}
