@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: .75rem;
}

body {
  font-family: zeitung, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #1d1550;
}

.form-radio {
  @apply shadow-inner appearance-none inline-block align-middle select-none flex-shrink-0 rounded-full h-5 w-5 text-primary bg-white border border-gray-300 transition-colors;
  background-origin: border-box;
}

.form-radio:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
  background-size: 130% auto;
  @apply bg-current bg-center bg-no-repeat border-transparent;
}

.form-radio:hover {
  @apply border-gray-400;
}

.form-radio:focus {
  @apply ring ring-primary ring-opacity-20 outline-none;
}

@responsive {
  .shadow-top {
    box-shadow: 0 -6px 6px -6px #aaa;
  }
}
